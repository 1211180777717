

* {
    box-sizing: border-box;
}


html, body {
    margin: 0;
}

.header{
    background-color: green;
    margin-bottom: 10px;
    padding: 20px;
    border: 2px;
    border-style: solid;
    border-color: white;

    display: flex;
    justify-content: center;
    align-items: center;
}
.main{    
    background-color: white;
}
.footer{
    margin-top: auto;
    background-color: orange;
}

.img{
    display: none;
}